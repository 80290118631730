import React from 'react'
import MediaQuery from 'react-responsive'
import coupon_menu from './../../../images/create-coupon/Coupon_menu.webp'
import coupon_menu_mobile from './../../../images/create-coupon/Coupon_menu_m.webp'
import create_coupon from './../../../images/create-coupon/Create_coupon.webp'
import create_coupon_mobile from './../../../images/create-coupon/Create_coupon_m.webp'
import coupon_term from './../../../images/create-coupon/Coupon_term.webp'
import coupon_term_mobile from './../../../images/create-coupon/Coupon_term_m.webp'
import coupon_detail from './../../../images/create-coupon/Coupon_detail.webp'
import coupon_detail_mobile from './../../../images/create-coupon/Coupon_detail_m.webp'
import coupon_detail_2 from './../../../images/create-coupon/Coupon_detail_2.webp'
import coupon_detail_mobile_2 from './../../../images/create-coupon/Coupon_detail_m_2.webp'
import coupon_detail_3 from './../../../images/create-coupon/Coupon_detail_3.webp'
import coupon_detail_mobile_3 from './../../../images/create-coupon/Coupon_detail_m_3.webp'
import coupon_detail_4 from './../../../images/create-coupon/Coupon_detail_4.webp'
import coupon_detail_mobile_4 from './../../../images/create-coupon/Coupon_detail_m_4.webp'
import coupon_detail_5 from './../../../images/create-coupon/Coupon_detail_5.webp'
import coupon_detail_mobile_5 from './../../../images/create-coupon/Coupon_detail_m_5.webp'
import coupon_detail_6 from './../../../images/create-coupon/Coupon_detail_6.webp'
import coupon_detail_mobile_6 from './../../../images/create-coupon/Coupon_detail_m_6.webp'
import coupon_detail_7 from './../../../images/create-coupon/Coupon_detail_7.webp'
import coupon_detail_mobile_7 from './../../../images/create-coupon/Coupon_detail_m_7.webp'
import coupon_detail_8 from './../../../images/create-coupon/Coupon_detail_8.webp'
import coupon_detail_mobile_8 from './../../../images/create-coupon/Coupon_detail_m_8.webp'
import coupon_detail_9 from './../../../images/create-coupon/Coupon_detail_9.webp'
import coupon_detail_mobile_9 from './../../../images/create-coupon/Coupon_detail_m_9.webp'
import coupon_detail_10 from './../../../images/create-coupon/Coupon_detail_10.webp'
import coupon_detail_mobile_10 from './../../../images/create-coupon/Coupon_detail_m_10.webp'
import coupon_detail_11 from './../../../images/create-coupon/Coupon_detail_11.webp'
import coupon_detail_mobile_11 from './../../../images/create-coupon/Coupon_detail_m_11.webp'
export default function createCoupon(props) {
  return (
    <div className="fade-in" lang="en">
      {/* Start Desktop size part */}
      <MediaQuery minWidth={1024}>
        <div className="container-1420 m-auto">
          <div className="h-16"></div>
          <div
            className="text-body-header font-bold mt-16
                        max:text-size-55 2xl:text-size-50 xl:text-size-45 lg:text-size-40"
          >
            Creating New Coupons
          </div>
          <br />
          <br />
          <p>To create new coupons for your shop, follow these steps:</p>
          <br />
          <ul className="list-decimal">
            <li>
              <span>From the main page, select "Coupon"</span>
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_menu"
              src={coupon_menu}
            />
            <br />
            <br />
            <li>Click on “Add” button.</li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="create_coupon"
              src={create_coupon}
            />
            <br />
            <br />
            <li>
              A pop up message will display the Terms & Conditions, press
              “ACCEPT” to create a coupon.
              <br />
              <br />
              <br />
              <img
                className="mx-auto w-90p"
                alt="coupon_term"
                src={coupon_term}
              />
              <br />
              <p>
                *** If the shop has not entered the bank account information,
                click on "MANAGE SHOP" to add bank account information This can
                only be done by the shop owner.
              </p>
            </li>
            <br />
            <li>
              <p>As a next step, enter the required informations as follows:</p>
              {/* <br />
              <img
                className="mx-auto w-90p"
                alt="coupon_detail"
                src={coupon_detail}
              />
              <br /> */}
              <p>Coupon Name (Thai) : Create your coupon’s name (Thai)</p>
              <p>Coupon Name (English) : Create your coupon’s name (English)</p>
              <p>Coupon Service : Choose service type</p>
              <br />
              <img
                className="mx-auto w-90p"
                alt="coupon_detail_2"
                src={coupon_detail_2}
              />
              <br />
              <br />
              <p>
                Number of Coupons : Enter the number of coupons (minimum 20
                units)
              </p>
              <p>
                Coupon Price : Create price ( starting from 50-1000 bath and
                including fee 10% /unit)
              </p>
              <br />
              <img
                className="mx-auto w-90p"
                alt="coupon_detail_5"
                src={coupon_detail_5}
              />
              <br />
              <p>
                Coupon Sale Period : Select starting date and ending date. (The
                staring date must be not less than 7 days from approval date)
              </p>
              <br />
              <div className="grid grid-cols-5">
                <img
                  className="mx-auto col-span-2"
                  alt="coupon_detail_3"
                  src={coupon_detail_3}
                />
                <div className="col-span-1"></div>
                <img
                  className="mx-auto col-span-2"
                  alt="coupon_detail_4"
                  src={coupon_detail_4}
                />
              </div>
              <br />
              <p>
                Appointments can be made after purchasing the coupon : Set
                number of days for appointments can be made after purchasing the
                coupon. The number of days must be between 1-30 days.
              </p>
              <p>Coupon Details (Thai) : Create conditions for buyer (Thai)</p>
              <p>
                Coupon Details (English) : Create conditions for buyer
                (English) 
              </p>
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_6"
              src={coupon_detail_6}
            />
            <br />
            <li>
              After enter all the required information, click on “Create” to
              create coupons.
            </li>
            <li>
              Once you have clicked the create button, you can upload coupon
              picture. Then press "SAVE".
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_7"
              src={coupon_detail_7}
            />
            <br />
            <li>
              Now, you can submit coupons to get approval from AAppoint by
              clicking on "SUBMIT FOR APPROVAL".
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_8"
              src={coupon_detail_8}
            />
            <br />
            <li>
              A pop up message will display a notification confirming the
              submission of the request for approval, press "OK".
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_9"
              src={coupon_detail_9}
            />
            <br />
            <li>
              When you confirm, the request will be sent to AAppoint and the
              status will be "Waiting for approval".
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_10"
              src={coupon_detail_10}
            />
            <br />
            <li>
              To return the coupon page, click on the coupon menu. A list of
              coupons created by the shop will be displayed. You can track
              approval status from this page.
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_11"
              src={coupon_detail_11}
            />
            <br />
            <li>
              Once the coupon is approved, you can sell coupon to your customer.
            </li>
          </ul>
        </div>
      </MediaQuery>
      {/* End Desktop size part */}
      {/* Start Mobile size part */}
      <MediaQuery maxWidth={1023}>
        <div className="h-32 md:h-48 sm:h-40"></div>
        <div className="container-mobile-content m-auto">
          <div className="text-body-header font-bold md:text-size-20 sm:text-size-18">
            Creating New Coupons
          </div>
          <br />
          <br />
          <p>To create new coupons for your shop, follow these steps:</p>
          <br />
          <ul className="list-decimal">
            <li>From the main page, select "Coupon"</li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_menu_mobile"
              src={coupon_menu_mobile}
            />
            <br />
            <li>Click on “Add” button.</li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="create_coupon_mobile"
              src={create_coupon_mobile}
            />
            <br />
            <li>
              A pop up message will display the Terms & Conditions, press
              “ACCEPT” to create a coupon.
              <p>
                *** If the shop has not entered the bank account information,
                click on "MANAGE SHOP" to add bank account information This can
                only be done by the shop owner.
              </p>
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_term_mobile"
              src={coupon_term_mobile}
            />
            <br />
            <li>
              <p>As a next step, enter the required informations as follows:</p>
              <br />
              <img
                className="mx-auto w-90p"
                alt="coupon_detail_mobile"
                src={coupon_detail_mobile}
              />
              <br />
              <p>Coupon Name (Thai) : Create your coupon’s name (Thai)</p>
              <p>Coupon Name (English) : Create your coupon’s name (English)</p>
              <p>Coupon Service : Choose service type</p>
              <br />
              <img
                className="mx-auto w-90p"
                alt="coupon_detail_mobile_2"
                src={coupon_detail_mobile_2}
              />
              <br />
              <p>
                Number of Coupons : Enter the number of coupons (minimum 20
                units)
              </p>
              <p>
                Coupon Price : Create price ( starting from 50-1000 bath and
                including fee 10% /unit)
              </p>
              <br />
              <img
                className="mx-auto w-90p"
                alt="coupon_detail_mobile_5"
                src={coupon_detail_mobile_5}
              />
              <br />
              <p>
                Coupon Sale Period : Select starting date and ending date. (The
                staring date must be not less than 7 days from approval date)
              </p>
              <br />
              <img
                className="mx-auto w-90p"
                alt="coupon_detail_mobile_3"
                src={coupon_detail_mobile_3}
              />
              <br />
              <img
                className="mx-auto w-90p"
                alt="coupon_detail_mobile_4"
                src={coupon_detail_mobile_4}
              />
              <br />
              <p>
                Appointments can be made after purchasing the coupon : Set
                number of days for appointments can be made after purchasing the
                coupon. The number of days must be between 1-30 days.
              </p>
              <p>Coupon Details (Thai) : Create conditions for buyer (Thai)</p>
              <p>
                Coupon Details (English) : Create conditions for buyer
                (English) 
              </p>
              <br />
              <img
                className="mx-auto w-90p"
                alt="coupon_detail_mobile_6"
                src={coupon_detail_mobile_6}
              />
              <br />
            </li>
            <li>
              After enter all the required information, click on “Create” to
              create coupons.
            </li>
            <li>
              Once you have clicked the create button, you can upload coupon
              picture. Then press "SAVE".
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_mobile_7"
              src={coupon_detail_mobile_7}
            />
            <br />
            <li>
              Now, you can submit coupons to get approval from AAppoint by
              clicking on "SUBMIT FOR APPROVAL".
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_mobile_8"
              src={coupon_detail_mobile_8}
            />
            <br />
            <li>
              A pop up message will display a notification confirming the
              submission of the request for approval, press "OK".
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_mobile_9"
              src={coupon_detail_mobile_9}
            />
            <br />
            <li>
              When you confirm, the request will be sent to AAppoint and the
              status will be "Waiting for approval".
            </li>
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_mobile_10"
              src={coupon_detail_mobile_10}
            />
            <br />
            <li>
              To return the coupon page, click on the coupon menu. A list of
              coupons created by the shop will be displayed. You can track
              approval status from this page.
            </li>
            <br />
            <img
              className="mx-auto w-90p"
              alt="coupon_detail_mobile_11"
              src={coupon_detail_mobile_11}
            />
            <br />
            <li>
              Once the coupon is approved, you can sell coupon to your customer.
            </li>
          </ul>
        </div>
      </MediaQuery>
      {/* End Mobile size part */}
    </div>
  )
}
